import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import {
  Column,
  SEOTitle,
  Button,
  Container,
  Subtitle,
  Text,
} from "../theme/index"
import { Responsive } from "../theme/styles"
import { StaticImage } from "gatsby-plugin-image"
import { ButtonContainer, SpanBar, Process } from "../components/index"

const Grid = styled.div`
  ${Responsive.sm`
  display: block;
  width: 100%;
  margin-top: ${props => (props.guests ? "25px" : "0")};
  margin-bottom: ${props => (props.marginbottom ? "75px" : "0")};
`}
  ${Responsive.lg`
display: grid;
grid-template-columns: ${props =>
    props.guests
      ? "repeat(auto-fit, minmax(200px, 1fr))"
      : "repeat(auto-fit, minmax(600px, 1fr))"};
grid-gap: ${props => (props.guests ? "50px" : "15px")};
`}
`

const StyledColumn = styled(Column)`
  ${Responsive.sm`
    margin-bottom: 25px;
  `}
  ${Responsive.lg`
  margin-bottom: 0px;
`}
`

const ImageColumn = styled(Column)`
  ${Responsive.sm`
margin: 75px auto;
width: 100%;
`}
  ${Responsive.lg`
width: 50%;
`}
`

const Podcast = () => {
  const guests = useStaticQuery(graphql`
    query {
      arthur: file(relativePath: { eq: "guests/arthur.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 250
            height: 250
          )
        }
      }
      isaac: file(relativePath: { eq: "guests/isaac.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 250
            height: 250
          )
        }
      }
      tania: file(relativePath: { eq: "guests/tania.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 250
            height: 250
          )
        }
      }
      katerina: file(relativePath: { eq: "guests/katerina.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 250
            height: 250
          )
        }
      }
      kelly: file(relativePath: { eq: "guests/kelly.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 250
            height: 250
          )
        }
      }
      darshita: file(relativePath: { eq: "guests/darshita.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 250
            height: 250
          )
        }
      }
      anisa: file(relativePath: { eq: "guests/anisa.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 250
            height: 250
          )
        }
      }
      christian: file(relativePath: { eq: "guests/christian.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 250
            height: 250
          )
        }
      }
      heidi: file(relativePath: { eq: "guests/heidi.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 250
            height: 250
          )
        }
      }
      toby: file(relativePath: { eq: "guests/toby.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 250
            height: 250
          )
        }
      }
      kevin: file(relativePath: { eq: "guests/kevin.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 250
            height: 250
          )
        }
      }
      leena: file(relativePath: { eq: "guests/leena.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 250
            height: 250
          )
        }
      }
      jennifer: file(relativePath: { eq: "guests/jennifer.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 250
            height: 250
          )
        }
      }
    }
  `)

  const data = [
    {
      image: guests.arthur.childImageSharp.gatsbyImageData,
      name: "Arthur Burris Jr.",
      company: "Porteiro",
      btnText: "Website",
      link: "https://porteiro.com/",
    },
    {
      image: guests.isaac.childImageSharp.gatsbyImageData,
      name: "Isaac Ettert",
      company: "Identity",
      btnText: "Website",
      link: "https://www.identitylearning.co/",
    },
    {
      image: guests.tania.childImageSharp.gatsbyImageData,
      name: "Tania Dsouza",
      company: "Human Matter",
      btnText: "Website",
      link: "https://human-matter.com/",
    },
    {
      image: guests.katerina.childImageSharp.gatsbyImageData,
      name: "Katerina Bohle Carbonell",
      company: "Human Matter",
      btnText: "Website",
      link: "https://human-matter.com/",
    },
    {
      image: guests.kelly.childImageSharp.gatsbyImageData,
      name: "Kelly O'Brien",
      company: "Kapshure",
      btnText: "Website",
      link: "https://www.kapshure.com/",
    },
    {
      image: guests.darshita.childImageSharp.gatsbyImageData,
      name: "Darshita Gillies",
      company: "MAANCH",
      btnText: "Website",
      link: "https://maanch.com/",
    },
    {
      image: guests.toby.childImageSharp.gatsbyImageData,
      name: "Toby Egbuna",
      company: "Chezie",
      btnText: "Website",
      link: "https://www.chezie.co/",
    },
    {
      image: guests.anisa.childImageSharp.gatsbyImageData,
      name: "Anisa Maruschak",
      company: "The Circular Consumer",
      btnText: "Website",
      link: "https://thecircularconsumer.com/",
    },
    {
      image: guests.christian.childImageSharp.gatsbyImageData,
      name: "Christian LeCorp",
      company: "Atlas Prime NRG",
      btnText: "Website",
      link: "https://atlasprimenrg.com/",
    },
    {
      image: guests.jennifer.childImageSharp.gatsbyImageData,
      name: "Jennifer Strout",
      company: "MotivateU",
      btnText: "Website",
      link: "https://www.motivateu.net/",
    },
    {
      image: guests.leena.childImageSharp.gatsbyImageData,
      name: "Leena Parmar",
      company: "Citrus Connect Recruitment",
      btnText: "Website",
      link: "https://www.citrus-connect.co.uk/",
    },
    {
      image: guests.kevin.childImageSharp.gatsbyImageData,
      name: "Kevin White",
      company: "Global Vision 2020",
      btnText: "Website",
      link: "https://gv2020.org/",
    },
  ]

  return (
    <Layout title="Talkin' Solutions Podcast">
      <Container>
        <ImageColumn>
          <StaticImage
            src="../images/podcasthero.png"
            alt="CheshTech Digital Agency: Modern websites, apps, and digital marketing"
            placeholder="blurred"
            style={{ marginBottom: "0px", borderRadius: "10px" }}
          />
        </ImageColumn>
        <Grid marginbottom>
          <StyledColumn>
            <SEOTitle>
              Highlighting Social Entrepreneurs and Their Solutions to Societal
              Problems
            </SEOTitle>
            <Text margin="10px 0">
              Not only do we love working with companies and entrepreneurs that
              are making a difference in the world and their communities, we
              love to highight them too! Welcome to the Talkin' Solutions
              Podcast brought to you by CheshTech. This podcast is all about
              talking solutions. We want to put the focus on your solution to
              societal issues so we can bring some more positivity and optimism
              in the world.
            </Text>
            <Text margin="0 0 15px 0">
              CheshTech CEO Will Cheshier, a former radio broadcaster, hosts the
              podcast and puts the spotlight on you and your company to our
              audience of listeners. We'll talk about how your solution solves a
              societal problem, how people can get behind what you're doing,
              your company's goals, and many other topics! Check out our
              Instagram page and listen to an episode to check it out!
              Interested in coming on the show to highlight your company and
              your solution? Contact us today!
            </Text>
            <ButtonContainer
              type="podcast"
              link="/contact"
              btnText="Be a Guest"
              btnText2="Listen to Podcast"
            />
          </StyledColumn>
          <Column>
            <iframe
              title="Talkin' Solutions"
              allowtransparency="true"
              height="415"
              width="100%"
              style={{ border: "none" }}
              scrolling="no"
              data-name="pb-iframe-player"
              src="https://www.podbean.com/player-v2/?i=5353s-bc991a-pbblog-playlist&share=1&download=1&rtl=0&fonts=Arial&skin=1&font-color=auto&order=episodic&limit=30&filter=all&ss=440123a78bc51a981426c98af4ef04b1&btn-skin=7&size=315"
              allowfullscreen=""
            ></iframe>
          </Column>
        </Grid>
      </Container>
      <SpanBar />
      <Container>
        <Column margin="75px 0 75px 0">
          <SEOTitle>Our Exceptional Guests</SEOTitle>
          <Grid guests>
            {data.map((key, index) => {
              const image = getImage(key.image)
              return (
                <StyledColumn key={index} margin="0 auto" height="100%">
                  <GatsbyImage
                    image={image}
                    alt="CheshTech William Cheshier"
                    placeholder="blurred"
                    style={{ marginBottom: "10px", borderRadius: "50%" }}
                  />
                  <Column>
                    <Subtitle>{key.name}</Subtitle>
                    <Text fontweight="bold">{key.company}</Text>
                    <a href={key.link} target="__blank">
                      <Button margin="15px 0 0 0">{key.btnText}</Button>
                    </a>
                  </Column>
                </StyledColumn>
              )
            })}
          </Grid>
        </Column>
      </Container>
      <Process type="podcast" />
    </Layout>
  )
}
export default Podcast
// <ContactForm />
// <Testimonials type="podcast" />
